// adapted from: https://gist.github.com/steve8708/1a785503bf33c01728130cfa9a594ab8

function loadScript(url: string) {
  return new Promise<void>((resolve) => {
    const script = document.createElement('script')
    script.async = true
    script.src = url
    script.addEventListener('load', () => {
      resolve()
    })
    document.body.appendChild(script)
  })
}

export async function bootIntercom(open = true) {
  if (window.location.href.includes('/invoices/')) {
    // Do not boot Intercom on invoices page.
    // It will show up on the PDFs as well.
    return
  }

  if (window.location.pathname.includes('/reset-password')) {
    // Do not boot intercom on the reset-password page.
    // It would sent contain sensitive data to a third party.
    return
  }

  const app_id = window.intercomSettings?.app_id
  await loadScript(`https://widget.intercom.io/widget/${app_id ?? 'qiqpfgjg'}`)
  window.Intercom('boot', window.intercomSettings ?? { app_id: 'qiqpfgjg' })
  // the intercom widget needs to be shown once for it to stay closed, in button shape
  // asked on Intercom about this behavior and will patch it if necessary
  // https://community.intercom.com/messenger-8/just-showing-the-intercom-widget-after-boot-without-show-hide-7087
  window.Intercom('show')
  window.Intercom('hide')

  if (open) {
    window.Intercom('show')
  }
}
