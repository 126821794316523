// Sometimes window.Intercom is available, but Intercom is not loaded due to adblockers.
// (https://community.intercom.com/t/is-there-a-way-to-detect-if-intercom-messenger-is-available/940)
const trackIntercomLoadState = () => {
  window.intercomLoaded = false

  if (window.Intercom) {
    window.Intercom('onUnreadCountChange', () => {
      window.intercomLoaded = true
    })
  }
}

export default trackIntercomLoadState
