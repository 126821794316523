window.requestIdleCallback ||= (cb) =>
  setTimeout(() => {
    const start = Date.now()
    // eslint-disable-next-line node/no-callback-literal
    cb({
      didTimeout: false,
      timeRemaining() {
        return Math.max(0, 50 - (Date.now() - start))
      },
    })
  }, 1) as unknown as number

window.cancelIdleCallback ||= (id) => {
  clearTimeout(id)
}
