import type { LoginData } from '~/features/workspaces/types'

const activateIntercom = (loginData: LoginData) => {
  if (!loginData?.app_id) {
    return
  }

  if (!window.Intercom) {
    return
  }

  // See https://github.com/transloadit/content/issues/1761
  if (loginData.isImpersonated) {
    console.log('Impersonating, disabling intercom')
    window.Intercom('shutdown')
    return
  }

  window.intercomSettings = {
    app_id: loginData.app_id,
    email: loginData.email,
    created_at: loginData.created_epoch,
    name: loginData.name ?? undefined,
    user_id: loginData.id,
    user_hash: loginData.intercom_hash,
    user_url: `https://transloadit.com/c/admin/workspace/${loginData.id}`,
    plan_slug: loginData.plan_slug,
  }

  window.Intercom('reattach_activator')
  window.Intercom('update', window.intercomSettings)
}

export default activateIntercom
